import { Link } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TrustSection from "../components/sections/trustSection"
import HeadingGroup from "../components/ui/headingGroup"
import Banner from "../components/ui/banner"
import GetStartedSection from "../components/sections/getStartedSection"
import RequestDemoDrawer from "../components/drawers/requestDemoDrawer";
import SignUpDrawer from "../components/drawers/signUpDrawer";
import PricingDrawer from "../components/drawers/pricingDrawer";
import PageBreak from "../components/ui/pageBreak";
import ImageGroup from "../components/ui/imageGroup";
import styled from "styled-components";
import PrimaryButton from "../components/ui/primaryButton";

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const RetailPage = () => {
  const [openPriceCalculator, setOpenPriceCalculator] = useState(false);
  const [openRequestDemo, setOpenRequestDemo] = useState(false);
  const [openGettingStarted, setOpenGettingStarted] = useState(false);

  const handlePriceCalculatorClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenPriceCalculator(!openPriceCalculator);
      event.preventDefault();
    }
  }

  const handleRequestDemoClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenRequestDemo(!openRequestDemo);
      event.preventDefault();
    }
  }

  const handleGettingStartedClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenGettingStarted(!openGettingStarted);
      event.preventDefault();
    }
  }

  return (
    <Layout>
      <SEO title="Retail Biometric Solutions" />
      <Banner>
        <p>
          Are you looking for an immediate price estimate?&nbsp;<Link to="/pricing" onClick={handlePriceCalculatorClick}>Price Calculator</Link>
        </p>
      </Banner>
      <Hero
        handleRequestDemoClick={handleRequestDemoClick}
        handleGettingStartedClick={handleGettingStartedClick}
        statement="Do your stores require"
        punchlines={["Multisite Control?", "Time and Attendance?", "Real Time Monitoring?", "Shift Control?", "Payroll Integration?"]}
        caption="We provide cloud-based Access Control as well as Time and Attendance solutions which rely on fingerprint or facial recognition. Track your employees' attendance against configurable work rules and integrate directly into your payroll provider."
      ></Hero>
      <TrustSection />
      <HeadingGroup heading="Biometric Attendance Tracking for Retail">
        <p>
          Beyond Attendance fulfils multiple requirements within a single platform for the Retail industry.
        </p>
        <p>
          The rapid and accurate collection of biometric attendance and leave records eliminates the need for manual data collection.
        </p>
        <p>
          Limited, specified access can be given to store managers to allow them to adjust shift rosters and approve overtime requests for their staff to a regional manager or head office for approval by an administrative user.
        </p>
        <p>
          Our advanced rostering tool allows for shift and work hour forecasting to help optimise operations. Advanced workday rules can be applied on multiple levels from individuals all the way to entire regions.
        </p>
        <p>
          Using our Shift Sense Function, our system's smart design will automatically detect and assign shifts based on employees' start and end scans. This helps ease the need for managers to constantly update their shift rosters.
        </p>
      </HeadingGroup>
      <PageBreak>Take a look at some of our key features below&nbsp;<span role="img" aria-label="fire">🔥</span></PageBreak>

      <ImageGroup reverse={false} image="../images/pricing.svg" heading="Competitive Pricing">
        <p>
          Our product has a scaled pricing model which allows you to choose the Time and Attendance tracking package that best suits your needs and offers maximum value for money.  The system is a month to month contract so you are not locked in by fixed terms and your monthly bill will depend on the number of employees on the system.
        </p>
        <ButtonContainer>
          <PrimaryButton to="/pricing" onClick={handlePriceCalculatorClick} padding="0.8rem" margin="0.8rem 0.8rem 0.8rem 0" fontSize="1rem">Price Calculator</PrimaryButton>
        </ButtonContainer>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/role.svg" heading="Role Based Access">
        <p>
          Beyond Attendance allows for multiple levels of control to be granted to different users. For example, a site manager can be granted access that allows them to request modifications to work times and shifts. These requests would then need to be approved or denied by an area manager or an administrator at head office. We can also limit which functions of the software are accessed. A reporter can generate and view reports while an HR clerk has the ability create profiles for new people.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/multisite.svg" heading="Multisite Control">
        <p>
          Due to the system being cloud-based, multiple sites can be controlled from a single centralised location. The devices link up in real-time allowing for immediate data retrieval. This ensures transparency in the workplace and eliminates the need to wait for managers to manually send time sheets and other documentation to head office. The instant reporting saves time and allows for quick action to be taken if necessary.
        </p>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/configurable.svg" heading="Roster Forecasting">
        <p>
          Beyond Attendance’s Advanced Rostering Tool provides the ability to accurately forecast total hour worked per store and per employee. This enables stores to ensure they have the minimum number of employee’s, in a particular role, scheduled to work each day. It also ensured people don’t work more than what their contractual hours allow in a single week.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/configurable.svg" heading="Configurable Shift Rules">
        <p>
          Work rules such as automatic deduction of lunch and tea breaks, removal of duplicated scans and automated rounding of start and end times, can be customized to suit each company or store uniquely. Using Machine Learning Technology, our system's Shift Sense Function will automatically detect and assign shifts based on employees' start and end scans.
        </p>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/configurable.svg" heading="Employee Leave Management">
        <p>
          Beyond Attendance includes a leave management system for tracking and maintaining leave records. The system allows you to create and modify leave codes and types depending on your requirements. In addition to the basics, Beyond Attendance also allows for online document storage for items such as sick notes and letters of warning. These leave records can then be exported into your payroll system when exporting your payroll files.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/biometric.svg" heading="Multiple Biometric Options">
        <p>
          You will receive full support for a wide range of biometric devices. Available options include fingerprint and facial recognition, blood vessel mapping, thermal temperature detection and palm scanning technologies. Additional options for smaller teams include USB based units that can simply be linked directly into a Microsoft Windows PC or Android Device through which people can scan to clock their activity during the day or night.
        </p>
      </ImageGroup>

      <GetStartedSection
        handleRequestDemoClick={handleRequestDemoClick}
        handleGettingStartedClick={handleGettingStartedClick} />

      <RequestDemoDrawer open={openRequestDemo} handleClose={() => setOpenRequestDemo(false)} />
      <SignUpDrawer open={openGettingStarted} handleClose={() => setOpenGettingStarted(false)} />
      <PricingDrawer open={openPriceCalculator} handleClose={() => setOpenPriceCalculator(false)} />
    </Layout>
  )
}

export default RetailPage
